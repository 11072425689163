<template>
  <!-- 菜单管理页面 -->
	<div class="box">
		<div v-show="$route.meta.isShow">
			<!-- 顶部 -->
			<div class="header">
				<div class="title">用户列表</div>
				<div class="content">
					<a-form layout="inline" :model="formState">
						<a-form-item label="手机号码">
							<a-input v-model.trim="formState.phone" placeholder="请输入手机号码" style="width: 248px" />
						</a-form-item>
						<a-form-item label="代理姓名">
							<a-input v-model="formState.agentName" placeholder="请输入代理姓名" style="width: 248px" />
						</a-form-item>
						<a-form-item label="代理区域">
							 <a-cascader v-model="formState.region" :options="cityOptions" @change="cityChange" placeholder="请选择区域" style="width: 248px"/>
						</a-form-item>
						<a-form-item>
                            <div style="display:flex;align-items: center;">
                                <a-button type="primary" html-type="submit" class="margin_right10" @click="searchClick">搜索</a-button>
                                <a-button v-if="agentLevel == 0" @click="exporTuserInit" html-type="submit">导出</a-button>
                            </div>
						</a-form-item>
					</a-form>
				</div>
			</div>
			<div class="tableStyle">
				<a-table :columns="columns" :data-source="tabledData" :row-key="record => record.userNo" :pagination="false" :loading="tableLoading" bordered :scroll="{ x: 1392 }">
                    <span slot="operation" slot-scope="operation, record">
						<span v-if="record.isStutas == 0">
							<a-popconfirm title="确定要启用此条数据吗?" ok-text="确定" cancel-text="取消" @confirm="enableClick(record)">
								<a class="margin_right10">启用</a>
							</a-popconfirm>
						</span>
						<span v-else>
							<a-popconfirm title="确定要禁用此条数据吗?" ok-text="确定" cancel-text="取消" @confirm="disableClick(record)">
								<a class="margin_right10">禁用</a>
							</a-popconfirm>
						</span>
						<a class="margin_right10" @click="editClick(record)">编辑</a>
					</span>
				</a-table>
				<div class="page">
					<MyPagination :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFns" v-show="tabledData.length > 0"/>
				</div>
			</div>
		</div>
        <a-modal v-model:visible="visible" centered title="导出">
            <template #footer>
                <div style="display: flex;justify-content: flex-end">
                    <a-button key="back" @click="visible = false" style="margin-right: 20px">取消</a-button>
                    <download-excel
                        class = "export-excel-wrapper"
                        :data = "allList"
                        :fields = "tableDataName"
                        name = "用户列表.xls">
                        <a-button key="submit" @click="visible = false" type="primary">确定</a-button>
                    </download-excel>
                </div>
            </template>
            <p style="display: flex;align-items: center">
                <a-icon  type="info-circle" :style="{fontSize: '16px', color: '#FF8200'}"/>
                <span style="margin-left: 5px;">确定要导出列表的数据吗？</span>
            </p>
        </a-modal>
		<router-view />
	</div>
</template>

<script>
import { message } from "ant-design-vue";
import city from "@/utils/city.json";
import MyPagination from "@/components/pagination/MyPagination";
import { UserList,ExporTuser } from "@/request/api/personnelManage";
export default {
    components: { MyPagination },
    created () {
        this.cityOptions = city.address
        this.userListFn();
        this.agentLevel = JSON.parse(localStorage.getItem("user")).agentLevel // 0:超级管理员 1:市级代理 2:区域代理 3:推广大使
	},
	watch: {},
	data() {
        return {
            visible:false,
            agentLevel: '',
            cityOptions: [],

			pageNo: 1,
			pageSize:'',
            count: 0,

			formState: {
				phone: '',
				agentName: undefined,
                region: undefined,
                city:'',
			},
			tableLoading: true, // 表格的loading图
			isDisableSearch: false, // 搜索按钮是否禁用
            columns: [
				{
					title: "用户昵称",
					dataIndex: "nickname",
					width: "120px",
                    key: "nickname",
				},
				{
					title: "手机号",
					dataIndex: "phone",
					width: "136px",
					key: "phone",
				},
				{
					title: "用户注册时间",
					dataIndex: "registerTimt",
					width: "184px",
					key: "registerTimt",
				},
				{
					title: "市级代理",
					dataIndex: "cityAgentName",
					width: "120px",
					key: "cityAgentName",
				},
				{
					title: "区域代理",
					dataIndex: "countyAgentName",
					width: "120px",
					key: "countyAgentName",
				},
				{
					title: "推广大使",
					dataIndex: "popularizeAgentName",
					width: "120px",
					key: "popularizeAgentName",
				},
				{
					title: "用户所属地区",
					dataIndex: "userArea",
					width: "192px",
					key: "userArea",
				},
				{
					title: "用户服务代理区域",
					dataIndex: "agentArea",
					width: "192px",
					key: "agentArea",
				},
				{
					title: "用户服务代理名称",
					dataIndex: "agentName",
					width: "192px",
					key: "agentName",
				},
				{
					title: "用户来源",
					dataIndex: "userChannel",
					width: "136px",
                    key: "userChannel",
				},
			],
			tabledData: [],

            // 管理员导出数据
            allList: [],
            //导出用 设置表头数据
            tableDataName: {
                "用户昵称": "nickname",
                "手机号": "phone",
                "用户注册时间": "registerTimt",
                "市级代理": "cityAgentName",
                "区域代理": "countyAgentName",
                "推广大使": "popularizeAgentName",
                "用户所属地区": "userArea",
                "用户服务代理区域": "agentArea",
                "用户服务代理名称": "agentName",
                "用户来源": "userChannel",
            },
		};
	},
    methods: {
        alclick () {
            this.allList = this.tabledData
        },
		// 搜索按钮 , 回车搜索
        searchClick () {
            this.pageNo = 1
            this.tableLoading = true
            this.tabledData = []
			this.isDisableSearch = true;
			setTimeout(() => {
			this.isDisableSearch = false;
			}, 3000);
			this.userListFn();
        },

        // 点击页数
        showSizeChangeFns (current, pageSize) {
            this.tableLoading = true
			this.pageNo = current;
			this.pageSize = pageSize;
			this.userListFn();
        },

		// 请求菜单列表数据
		userListFn() {
            UserList({
                page: this.pageNo,
                phone: this.formState.phone,
                agentName:this.formState.agentName,
                city:this.formState.city,
            }).then(({ code, data }) => {
                if (code === 200) {
                    this.tableLoading = false;
                    this.tabledData = data.list;
                    this.count = data.count;
                } else {
                    this.tableLoading = false;
                }
			});
        },

        // 导出的数据
        exporTuserInit () {
            this.visible = true
            ExporTuser({
                phone: this.formState.phone,
                agentName:this.formState.agentName,
                city:this.formState.city,
            }).then(({ code, data }) => {
                if (code === 200) {
                    this.allList = data;
                }
			});
        },

        // 监听选择省市区
        cityChange (value, selectedOptions) {
            if (value.length == 0) {
                return false
            } else {
                this.formState.city = selectedOptions[1].label
            }
        },
	},
};
</script>

<style lang="less" scoped>
.box {
  min-height: 100%;
  // background-color: #fff;
  padding-bottom: 56px;
  .header{
    width: 100%;
    background-color: #fff;
	margin-bottom: 16px;
    .title{
      padding: 14px 16px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);;
    }
	.content{
		padding: 14px 16px;
	}
  }
  .tableStyle{
	background-color: #fff;
	padding: 16px;
	.page{
		display: flex;
		justify-content: flex-end;
		margin-top: 20px;
        padding-right: 10px;
	}
  }
}
</style>

import { render, staticRenderFns } from "./upertubeManage.vue?vue&type=template&id=908f4698&scoped=true&"
import script from "./upertubeManage.vue?vue&type=script&lang=js&"
export * from "./upertubeManage.vue?vue&type=script&lang=js&"
import style0 from "./upertubeManage.vue?vue&type=style&index=0&id=908f4698&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "908f4698",
  null
  
)

export default component.exports
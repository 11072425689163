<template>
    <!-- 菜单管理页面 -->
    <div class="box">
        <div v-show="$route.meta.isShow">
            <!-- 顶部 -->
            <div class="header">
                <div class="titles">
                    <div style="margin-right: 30px;cursor: pointer" :class="[isStyle?'styleC':'styleB']" @click="dnOneClick">订单列表</div>
                    <div class="istitle" @click="dnTowClick">
                        <span :class="[isClass?'styleC':'styleB']">历史订单列表</span>
                        <span class="dateStyle">（2022年07月以前）</span>
                    </div>
                </div>

                <div class="content" v-if="isStyle == true">
                    <a-form layout="inline" :model="formState">
                        <a-form-item label="手机号码">
                            <a-input v-model="formState.phone" placeholder="请输入手机号码" style="width: 248px"/>
                        </a-form-item>
                        <a-form-item label="代理名称">
                            <a-input v-model="formState.agentName" placeholder="请输入代理名称" style="width: 248px"/>
                        </a-form-item>
                        <a-form-item label="代理等级">
                            <a-select ref="select" allowClear v-model:value="formState.levelNo" placeholder="请选择代理等级" style="width: 248px">
                                <a-select-option v-for="item in levelNoArr" :key="item.levelNo">{{item.name }}</a-select-option>
                            </a-select>
                        </a-form-item>
                        <a-form-item label="代理区域" v-if="agentLevel == 0">
                            <a-cascader v-model="formState.region" :options="cityOptions" @change="cityChange" placeholder="请选择区域" style="width: 248px"/>
                        </a-form-item>
                        <a-form-item>
                            <div style="display:flex;align-items: center;">
                                <a-button type="primary" html-type="submit" class="margin_right10" @click="searchClick">搜索</a-button>
                                <a-button v-if="isExport" @click="orderListNum" html-type="submit">导出</a-button>
                            </div>
                        </a-form-item>
                    </a-form>
                </div>
                <div class="content" v-if="isClass == true">
                    <a-form layout="inline" :model="formState">
                        <a-form-item label="手机号码">
                            <a-input v-model="formState.phone" placeholder="请输入手机号码" style="width: 248px"/>
                        </a-form-item>
                        <a-form-item>
                            <div style="display:flex;align-items: center;">
                                <a-button type="primary" html-type="submit" class="margin_right10" @click="searchClick">搜索</a-button>
                            </div>
                        </a-form-item>
                    </a-form>
                </div>
            </div>
            <div class="tableStyle" v-if="isStyle == true">
                <a-table :columns="columns" v-if="agentLevel == 0 || agentLevel == 99" :data-source="tabledData" :row-key="record => record.id" :pagination="false" :loading="tableLoading" bordered :scroll="{ x: 1500 }">
                    <span slot="orderStatus" slot-scope="orderStatus, record">
						<a-badge v-if="record.orderStatus == 0" color="#F56C6C"/>
						<a-badge v-else-if="record.orderStatus == 1" color="#67C23A"/>
						<span>{{ record.orderStatus == 1 ? '已支付' : '已退款' }}</span>
					</span>
                    <span slot="orderType" slot-scope="orderType, record">
						<span>{{ record.orderType ? record.orderType : '--' }}</span>
					</span>
                </a-table>
                <a-table :columns="columns2" v-else-if="agentLevel == 1 || agentLevel == 2" :row-key="record => record.id" :data-source="tabledData" :pagination="false" :loading="tableLoading" bordered>
                    <span slot="orderStatus" slot-scope="orderStatus, record">
						<a-badge v-if="record.orderStatus == 0" color="#F56C6C"/>
						<a-badge v-else-if="record.orderStatus == 1" color="#67C23A"/>
						<span>{{ record.orderStatus == 1 ? '已支付' : '已退款' }}</span>
					</span>
                    <span slot="orderType" slot-scope="orderType, record">
						<span>{{ record.orderType ? record.orderType : '--' }}</span>
					</span>
                </a-table>
            </div>
            <div class="tableStyle" v-if="isClass == true">
                <a-table :columns="columnsHis" :data-source="tabledDataHis" :row-key="record => record.id" :pagination="false" :loading="tableLoading" bordered>
                    <span slot="orderStatus" slot-scope="orderStatus, record">
						<a-badge v-if="record.orderStatus == 0" color="#F56C6C"/>
						<a-badge v-else-if="record.orderStatus == 1" color="#67C23A"/>
						<span>{{ record.orderStatus == 1 ? '已支付' : '已退款' }}</span>
					</span>
                    <span slot="orderType" slot-scope="orderType, record">
						<span>{{ record.orderType ? record.orderType : '--' }}</span>
					</span>
                </a-table>
            </div>
            <div class="page" v-if="pageShow">
                <MyPagination :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFns"/>
            </div>
        </div>

        <a-modal v-model:visible="visible" centered title="导出">
            <template #footer>
                <div style="display: flex;justify-content: flex-end">
                    <a-button key="back" @click="visible = false" style="margin-right: 20px">取消</a-button>
                    <download-excel
                        v-if="agentLevel == 0 || agentLevel == 99"
                        class="export-excel-wrapper"
                        :data="allList"
                        :fields="tableDataName"
                        name="订单列表.xls">
                        <a-button key="submit" @click="visible = false" type="primary">确定</a-button>
                    </download-excel>
                    <download-excel
                        v-if="agentLevel == 1 || agentLevel == 2"
                        class="export-excel-wrapper"
                        :data="allList"
                        :fields="tableDataName1"
                        name="订单列表.xls">
                        <a-button key="submit" @click="visible = false" type="primary">确定</a-button>
                    </download-excel>
                </div>
            </template>
            <p style="display: flex;align-items: center">
                <a-icon type="info-circle" :style="{fontSize: '16px', color: '#FF8200'}"/>
                <span style="margin-left: 5px;">确定要导出列表的数据吗？</span>
            </p>
        </a-modal>

        <router-view/>
    </div>
</template>

<script>
import city from "@/utils/city.json";
import MyPagination from "@/components/pagination/MyPagination";
import {OrderList, ExportOrder, Oldorderlist} from "@/request/api/orderManage";
import {AgentLevel} from "@/request/api/agenManage";
import {codeFn, format} from "@/utils/tools";

export default {
    components: {MyPagination},
    created() {
        this.cityOptions = city.address
        this.orderListFn();
        this.agentlevel()
        this.agentLevel = JSON.parse(localStorage.getItem("user")).agentLevel // 0:超级管理员 1:市级代理 2:区域代理 3:推广大使
        if (codeFn("/agent/admin/order/exportorder")) this.isExport = true; // 导出权限
    },
    watch: {},
    data() {
        return {
            isStyle:true,
            isClass:false,
            isExport: false,
            visible: false,
            pageShow:true,
            cityOptions: [],
            agentLevel: '',
            pageNo: 1,
            pageSize: '',
            count: 0,

            formState: {
                phone: "",
                agentName: "",
                levelNo: undefined,
                city: '',
                county: '',
                region: [],
            },
            tableLoading: true, // 表格的loading图
            isDisableSearch: false, // 搜索按钮是否禁用
            columns: [
                {
                    title: "用户昵称",
                    dataIndex: "nickname",
                    width: "120px",
                    key: "nickname",
                    fixed: 'left',
                },
                {
                    title: "订单类型",
                    dataIndex: "businessType",
                    width: "120px",
                    key: "businessType",
                },
                {
                    title: "手机号",
                    dataIndex: "phone",
                    width: "136px",
                    key: "phone",
                },
                {
                    title: "商品名称",
                    dataIndex: "businessName",
                    width: "192px",
                    key: "businessName",
                },
                {
                    title: "商品定价",
                    dataIndex: "businessPrice",
                    width: "192px",
                    key: "businessPrice",
                },
                {
                    title: "订单金额",
                    dataIndex: "orderPrice",
                    width: "120px",
                    align: 'right',
                    key: "orderPrice",
                },
                {
                    title: "订单状态",
                    dataIndex: "orderStatus",
                    width: "120px",
                    key: "orderStatus",
                    scopedSlots: {customRender: "orderStatus"},
                },
                {
                    title: "收益",
                    dataIndex: "income",
                    width: "120px",
                    align: 'right',
                    key: "income",
                },
                {
                    title: "下单时间",
                    dataIndex: "payTime",
                    width: "184px",
                    key: "payTime",
                },
                {
                    title: "用户注册时间",
                    dataIndex: "registerTime",
                    width: "184px",
                    key: "registerTime",
                },
                {
                    title: "市级代理",
                    dataIndex: "cityAgent",
                    width: "120px",
                    key: "cityAgent",
                },
                {
                    title: "区域代理",
                    dataIndex: "countyAgent",
                    width: "120px",
                    key: "countyAgent",
                },
                {
                    title: "推广大使",
                    dataIndex: "popularizeAgen",
                    width: "120px",
                    key: "popularizeAgen",
                },
                {
                    title: "代理所属地区",
                    dataIndex: "agentArea",
                    width: "192px",
                    key: "agentArea",
                },
                {
                    title: "用户所属地区",
                    dataIndex: "userArea",
                    width: "192px",
                    key: "userArea",
                },
                {
                    title: "下单渠道",
                    dataIndex: "orderType",
                    width: "192px",
                    key: "orderType",
                    scopedSlots: {customRender: "orderType"},
                },
                {
                    title: "下单路径",
                    dataIndex: "orderPath",
                    width: "132px",
                    key: "orderPath",
                },
                {
                    title: "用户来源",
                    dataIndex: "registerChannel",
                    width: "136px",
                    key: "registerChannel",
                },
                {
                    title: "订单归属",
                    dataIndex: "orderChannel",
                    width: "136px",
                    key: "orderChannel",
                    fixed: 'right',
                },
            ],
            columns2: [
                {
                    title: "用户昵称",
                    dataIndex: "nickname",
                    width: "120px",
                    key: "nickname",

                },
                {
                    title: "订单类型",
                    dataIndex: "businessType",
                    width: "120px",
                    key: "businessType",
                },
                {
                    title: "手机号",
                    dataIndex: "phone",
                    width: "136px",
                    key: "phone",
                },
                {
                    title: "商品名称",
                    dataIndex: "businessName",
                    width: "192px",
                    key: "businessName",
                },
                {
                    title: "订单金额",
                    dataIndex: "orderPrice",
                    width: "120px",
                    align: 'right',
                    key: "orderPrice",
                },
                {
                    title: "订单状态",
                    dataIndex: "orderStatus",
                    width: "120px",
                    key: "orderStatus",
                    scopedSlots: {customRender: "orderStatus"},
                },
                {
                    title: "收益",
                    dataIndex: "income",
                    width: "120px",
                    align: 'right',
                    key: "income",
                },
                {
                    title: "下单时间",
                    dataIndex: "payTime",
                    width: "184px",
                    key: "payTime",
                },
                {
                    title: "下单渠道",
                    dataIndex: "orderType",
                    width: "136px",
                    key: "orderType",
                    scopedSlots: {customRender: "orderType"},
                },
                {
                    title: "用户来源",
                    dataIndex: "registerChannel",
                    width: "136px",
                    key: "registerChannel",
                },
                {
                    title: "订单归属",
                    dataIndex: "orderChannel",
                    width: "136px",
                    key: "orderChannel",
                },
            ],
            tabledData: [],

            // 历史订单列表
            columnsHis: [
                {
                    title: "用户昵称",
                    dataIndex: "nickname",
                    key: "nickname",
                },
                {
                    title: "订单类型",
                    dataIndex: "businessType",
                    key: "businessType",
                },
                {
                    title: "手机号",
                    dataIndex: "phone",
                    key: "phone",
                },
                {
                    title: "商品名称",
                    dataIndex: "businessName",
                    key: "businessName",
                },
                {
                    title: "商品定价",
                    dataIndex: "businessPrice",
                    key: "businessPrice",
                },
                {
                    title: "订单金额",
                    dataIndex: "orderPrice",
                    align: 'right',
                    key: "orderPrice",
                },
                {
                    title: "订单状态",
                    dataIndex: "orderStatus",
                    key: "orderStatus",
                    scopedSlots: {customRender: "orderStatus"},
                },
                {
                    title: "下单时间",
                    dataIndex: "payTime",
                    width: "184px",
                    key: "payTime",
                },
                {
                    title: "用户注册时间",
                    dataIndex: "registerTime",
                    width: "184px",
                    key: "registerTime",
                },
                {
                    title: "用户来源",
                    dataIndex: "registerChannel",
                    key: "registerChannel",
                },
                {
                    title: "订单归属",
                    dataIndex: "orderChannel",
                    key: "orderChannel",
                },
            ],
            tabledDataHis: [],

            //代理等级数组
            levelNoArr: [],
            // 需要导出的数据
            allList: [],
            //导出用 设置表头数据
            tableDataName: {
                "用户昵称": "nickname",
                "订单类型": "businessType",
                "订单编号": {
                    field: "orderNo",
                    callback: value => {
                        return "&nbsp;" + value
                    }
                },
                "手机号": "phone",
                "商品名称": "businessName",
                "订单金额": "orderPrice",
                "订单状态": {
                    field: "orderStatus",
                    callback: value => {
                        return value == 1 ? '已支付' : '已退款'
                    }
                },
                "收益": "income",
                "下单时间": "payTime",
                "用户注册时间": "registerTime",
                "市级代理": "cityAgent",
                "区域代理": "countyAgent",
                "推广大使": "popularizeAgen",
                "代理所属地区": "agentArea",
                "用户所属地区": "userArea",
                "下单渠道": "orderType",
                "下单路径": "orderPath",
                "用户来源": "registerChannel",
                "订单归属": "orderChannel",
            },
            tableDataName1: {
                "用户昵称": "nickname",
                "订单类型": "businessType",
                "订单编号": {
                    field: "orderNo",
                    callback: value => {
                        return "&nbsp;" + value
                    }
                },
                "手机号": "phone",
                "商品名称": "businessName",
                "订单金额": "orderPrice",
                "订单状态": {
                    field: "orderStatus",
                    callback: value => {
                        return value == 1 ? '已支付' : '已退款'
                    }
                },
                "收益": "income",
                "下单时间": "payTime",
                "下单渠道": "orderType",
                "用户来源": "registerChannel",
                "订单归属": "orderChannel",
            },
        }
    },
    methods: {
        dnOneClick(){
            this.isStyle = true
            this.isClass = false
            this.pageShow = false
            this.formState.phone = ''
            this.formState.agentName = ''
            this.formState.levelNo = undefined
            this.formState.city = ''
            this.pageNo = 1
            this.orderListFn()
        },

        dnTowClick(){
            this.isStyle = false
            this.isClass = true
            this.pageShow = false
            this.formState.phone = ''
            this.formState.agentName = ''
            this.formState.levelNo = undefined
            this.formState.city = ''
            this.pageNo = 1
            this.orderListHisFn()
        },

        showSizeChangeFns(current, pageSize) {
            this.pageNo = current;
            this.pageSize = pageSize;
            if(this.isStyle == true){
                this.orderListFn();
            }else{
                this.orderListHisFn();
            }
        },

        // 搜索按钮 , 回车搜索
        searchClick() {
            this.pageNo = 1
            this.tabledData = []
            this.isDisableSearch = true;
            setTimeout(() => {
                this.isDisableSearch = false;
            }, 3000);
            if(this.isStyle == true){
                this.orderListFn();
            }else{
                this.orderListHisFn();
            }
        },

        // 请求列表数据
        orderListFn() {
            this.tableLoading = true;
            OrderList({
                page: this.pageNo,
                phone: this.formState.phone,
                agentName: this.formState.agentName,
                levelNo: this.formState.levelNo,
                city: this.formState.city,
                // county:this.formState.county
            }).then(({code, data}) => {
                if (code === 200) {
                    this.tableLoading = false;
                    this.pageShow = true
                    this.tabledData = data.list;
                    this.count = data.count
                } else {
                    this.tableLoading = false;
                }
            });
        },

        // 获取历史订单列表
        orderListHisFn(){
            this.tableLoading = true;
            Oldorderlist({
                page: this.pageNo,
                phone: this.formState.phone,
            }).then(({code, data}) => {
                if (code === 200) {
                    this.tableLoading = false;
                    this.pageShow = true
                    this.tabledDataHis = data.list;
                    this.count = data.count
                } else {
                    this.tableLoading = false;
                }
            });
        },

        // 代理等级接口
        agentlevel() {
            AgentLevel({}).then(({data, code}) => {
                if (code == 200) {
                    this.levelNoArr = data
                }
            });
        },

        // 监听选择省市区
        cityChange(value, selectedOptions) {
            if (value.length == 0) {
                return false
            } else {
                this.formState.city = selectedOptions[1].label
            }
        },

        // 请求导出数据列表
        orderListNum() {
            this.visible = true
            ExportOrder({
                phone: this.formState.phone,
                agentName: this.formState.agentName,
                levelNo: this.formState.levelNo,
                city: this.formState.city,
            }).then(({code, data}) => {
                if (code === 200) {
                    this.allList = data
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.box {
    min-height: 100%;
    background-color: #fff;

    .header {
        width: 100%;
        background-color: #fff;
        margin-bottom: 16px;
        >.titles {
            padding: 14px 16px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            display: flex;
            font-size: 18px;
            font-weight: 600;
            .styleC{
                color:#FF8200 !important;
                cursor:pointer;
            }
            .styleC{
                color:#000;
                cursor:pointer;
            }
            >.istitle{
                width: 300px;
                display: flex;
                align-items: center;
                .dateStyle {
                    font-size: 12px;
                }
            }
        }

        .content {
            padding: 14px 16px;
        }
    }

    .tableStyle {
        background-color: #fff;
        padding: 16px;
    }
    .page {
        display: flex;
        justify-content: flex-end;
        padding-right: 25px;
        padding-bottom: 20px;
    }
}
</style>

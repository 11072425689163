<template>
  <!-- 菜单管理页面 -->
	<div class="box">
		<div v-show="$route.meta.isShow">
			<!-- 顶部 -->
			<div class="header">
				<div class="title">代理列表</div>
				<div class="content">
					<a-form layout="inline" :model="formState">
						<a-form-item label="代理状态">
							<a-select v-model="formState.status" allowClear placeholder="请选择代理状态" style="width: 248px">
                                <a-select-option :value="0">全部</a-select-option>
								<a-select-option :value="1">启用</a-select-option>
								<a-select-option :value="2">禁用</a-select-option>
                                <a-select-option :value="3">已过期</a-select-option>
							</a-select>
						</a-form-item>
						<a-form-item label="代理等级">
							<a-select v-model="formState.grade" allowClear placeholder="请选择代理等级" style="width: 248px" v-if="agentLevel == 0">
                                <a-select-option :value="0">全部</a-select-option>
								<a-select-option v-for="item in gradeList" :key="item.levelNo">{{ item.name }}</a-select-option>
							</a-select>
                            <a-select v-model="formState.grade" placeholder="请选择代理等级" style="width: 248px" v-else-if="agentLevel == 1">
								<a-select-option v-for="(item,index) in gradeList" :key="item.levelNo" v-if="index>=1">{{ item.name }}</a-select-option>
							</a-select>
						</a-form-item>
						<a-form-item label="代理姓名">
							<a-input v-model="formState.name" placeholder="请输入代理姓名" style="width: 248px" />
						</a-form-item>
						<a-form-item label="手机号码">
							<a-input v-model="formState.phone" placeholder="请输入手机号码" style="width: 248px" />
						</a-form-item>
						<a-form-item label="到期时间">
                            <a-range-picker v-model="formState.time" format="YYYY-MM-DD" style="width: 248px" @change="termChange"/>
						</a-form-item>
						<a-form-item>
							<a-button type="primary" html-type="submit" @click="searchClick">搜索</a-button>
						</a-form-item>
					</a-form>
				</div>
			</div>
			<div class="tableStyle">
				<a-table :columns="columns" v-if="agentLevel == 0" :data-source="tabledData" :pagination="false" :row-key="record => record.agentNo" :loading="tableLoading" bordered :scroll="{ x: 1500 }">
					<span slot="division" slot-scope="division, record">
						<span>{{ record.division }} %</span>
					</span>
                    <span slot="orderNum" slot-scope="orderNum, record">
						<span>{{ record.orderNum }} <span style="color:#FF8200;margin-left: 8px;" v-if="agentLevel == 0" @click="orderDetails(record)">详情</span></span>
					</span>
					<span slot="enableFlag" slot-scope="enableFlag, record">
						<a-badge v-if="record.enableFlag == 0" color="#F56C6C"/>
						<a-badge v-else color="#67C23A"/>
						<span>{{ record.enableFlag == 0 ? '禁用':'正常' }}</span>
					</span>
					<span slot="operation" slot-scope="operation, record">
						<span v-if="record.enableFlag == 0 && isShowEnable">
							<a-popconfirm title="确定要启用此条数据吗?" ok-text="确定" cancel-text="取消" @confirm="enableClick(record)">
								<a class="margin_right10">启用</a>
							</a-popconfirm>
						</span>
						<span v-if="record.enableFlag != 0 && isShowDisable">
							<a-popconfirm title="确定要禁用此条数据吗?" ok-text="确定" cancel-text="取消" @confirm="disableClick(record)">
								<a class="margin_right10">禁用</a>
							</a-popconfirm>
						</span>
						<a class="margin_right10" v-if="isShowEdit" @click="editClick(record)">编辑</a>
                        <a @click="isAppCode(record)">直播二维码</a>
					</span>
				</a-table>
                <a-table :columns="columns1" v-if="agentLevel == 1" :data-source="tabledData" :pagination="false" :row-key="record => record.agentNo" :loading="tableLoading" bordered :scroll="{ x: 1500 }">
					<span slot="division" slot-scope="division, record">
						<span>{{ record.division }} %</span>
					</span>
                    <span slot="orderNum" slot-scope="orderNum, record">
						<span>{{ record.orderNum }} <span style="color:#FF8200;margin-left: 8px;" v-if="agentLevel == 0" @click="orderDetails(record)">详情</span></span>
					</span>
					<span slot="enableFlag" slot-scope="enableFlag, record">
						<a-badge v-if="record.enableFlag == 0" color="#F56C6C"/>
						<a-badge v-else color="#67C23A"/>
						<span>{{ record.enableFlag == 0 ? '禁用':'正常' }}</span>
					</span>
					<span slot="operation" slot-scope="operation, record">
						<span v-if="record.enableFlag == 0 && isShowEnable">
							<a-popconfirm title="确定要启用此条数据吗?" ok-text="确定" cancel-text="取消" @confirm="enableClick(record)">
								<a class="margin_right10">启用</a>
							</a-popconfirm>
						</span>
						<span v-if="record.enableFlag != 0 && isShowDisable">
							<a-popconfirm title="确定要禁用此条数据吗?" ok-text="确定" cancel-text="取消" @confirm="disableClick(record)">
								<a class="margin_right10">禁用</a>
							</a-popconfirm>
						</span>
						<a class="margin_right10" v-if="isShowEdit" @click="editClick(record)">编辑</a>
                        <a @click="isAppCode(record)">直播二维码</a>
					</span>
				</a-table>
                <a-table :columns="columns2" v-if="agentLevel == 2" :data-source="tabledData" :pagination="false" :row-key="record => record.agentNo" :loading="tableLoading" bordered :scroll="{ x: 1500 }">
					<span slot="division" slot-scope="division, record">
						<span>{{ record.division }} %</span>
					</span>
                    <span slot="orderNum" slot-scope="orderNum, record">
						<span>{{ record.orderNum }} <span style="color:#FF8200;margin-left: 8px;" v-if="agentLevel == 0" @click="orderDetails(record)">详情</span></span>
					</span>
					<span slot="enableFlag" slot-scope="enableFlag, record">
						<a-badge v-if="record.enableFlag == 0" color="#F56C6C"/>
						<a-badge v-else color="#67C23A"/>
						<span>{{ record.enableFlag == 0 ? '禁用':'正常' }}</span>
					</span>
					<span slot="operation" slot-scope="operation, record">
						<span v-if="record.enableFlag == 0 && isShowEnable">
							<a-popconfirm title="确定要启用此条数据吗?" ok-text="确定" cancel-text="取消" @confirm="enableClick(record)">
								<a class="margin_right10">启用</a>
							</a-popconfirm>
						</span>
						<span v-if="record.enableFlag != 0 && isShowDisable">
							<a-popconfirm title="确定要禁用此条数据吗?" ok-text="确定" cancel-text="取消" @confirm="disableClick(record)">
								<a class="margin_right10">禁用</a>
							</a-popconfirm>
						</span>
						<a class="margin_right10" v-if="isShowEdit" @click="editClick(record)">编辑</a>
                        <a @click="isAppCode(record)">直播二维码</a>
					</span>
				</a-table>
				<div class="page">
					<a-button type="dashed" v-if="isShowAdd" @click="addMenu">+添加代理</a-button>
					<MyPagination :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFn" v-show="tabledData.length > 0"/>
				</div>
			</div>
		</div>

        <!--    展示二维码    -->
        <a-modal v-model="visibleShow" title="小程序直播二维码">
            <template #footer>
                <a-button key="back" @click="visibleShow= false">关闭</a-button>
            </template>
            <div class="contentCode" >
                <a-spin v-if="spinShow"/>
                <div class="codeItem" v-for="(item,index) in appCodeList" :key="item.code">
                    <div class="name">
                        <img class="img" :src="item.qrCode" alt="">
                        <span class="size">{{item.code}}</span>
                    </div>
                    <span class="sizexz" @click="down(item)">下载</span>
                </div>
            </div>
        </a-modal>

		<router-view />
	</div>
</template>

<script>
import { message } from "ant-design-vue";
import {AgentList, AgentLevel, Editenableflag, Makeqrcode} from "@/request/api/agenManage";
import MyPagination from "@/components/pagination/MyPagination";
import { codeFn, format } from "@/utils/tools";
export default {
	components: { MyPagination },
    created () {
        this.agentLevel = JSON.parse(localStorage.getItem("user")).agentLevel // 0:超级管理员 1:市级代理 2:区域代理 3:推广大使
        this.agentListFn();
        this.agentGrade()
		if (codeFn("/agent/admin/agent/addagent")) this.isShowAdd = true;
		if (codeFn("/agent/admin/agent/editenableflag/2")) this.isShowDisable = true;
		if (codeFn("/agent/admin/agent/editenableflag/1")) this.isShowEnable = true;
        if (codeFn("/agent/admin/agent/editagent/{id}")) this.isShowEdit = true;

	},
    watch: {
        $route (to) {
            if (to.path === "/agenManage/upertubeManage") {
                this.agentListFn();
            }
        },
    },
	data() {
        return {
            spinShow:true,
            visibleShow:false,
            agentLevel:'',
			user:{},
			pageNo: 1, // 当前页
			count: 0, // 列表的总条数
			formState: {
				status: undefined,
				grade: undefined,
				name:'',
				phone: '',
				time:[],
			},
			tableLoading: true, // 表格的loading图
            isDisableSearch: false, // 搜索按钮是否禁用
            // 管理员权限
			columns: [
				{
					title: "姓名",
					dataIndex: "agentName",
					width: "120px",
                    key: "agentName",
                    fixed: 'left',
				},
				{
					title: "手机号",
					dataIndex: "phone",
					width: "136px",
					key: "phone",
				},
				{
					title: "代理等级",
					dataIndex: "agentLevel",
					width: "120px",
					key: "agentLevel",
				},
				{
					title: "所属市级代理",
					dataIndex: "belongCity",
					width: "120px",
					key: "belongCity",
				},
                {
					title: "所属区域代理",
					dataIndex: "belongCounty",
					width: "120px",
					key: "belongCounty",
				},
				{
					title: "代理区域",
					dataIndex: "agentArea",
					width: "192px",
					key: "agentArea",
				},
				{
					title: "保证金",
					dataIndex: "earnesMoney",
					width: "120px",
					align:'right',
					key: "earnesMoney",
				},
				{
					title: "订单数量",
					dataIndex: "orderNum",
					width: "168px",
					key: "orderNum",
					align:'right',
					scopedSlots: { customRender: "orderNum" },
				},
				{
					title: "分成比例",
					dataIndex: "division",
					width: "120px",
					align:'right',
                    key: "division",
                    scopedSlots: { customRender: "division" },
				},
				{
					title: "累计收益",
					dataIndex: "totalIncome",
					width: "120px",
					align:'right',
					key: "totalIncome",
				},
				{
					title: "可提现收益",
					dataIndex: "canDrawCash",
					width: "120px",
					align:'right',
					key: "canDrawCash",
				},
				{
					title: "代理状态",
					dataIndex: "enableFlag",
					width: "120px",
					key: "enableFlag",
					scopedSlots: { customRender: "enableFlag" },
				},
				{
					title: "加入时间",
					dataIndex: "startTime",
					width: "184px",
					key: "startTime",
				},
				{
					title: "到期时间",
					dataIndex: "endTime",
					width: "184px",
					key: "endTime",
				},
				{
					title: "操作",
					dataIndex: "operation",
					width: "200px",
					key: "operation",
					scopedSlots: { customRender: "operation" },
					fixed: 'right',
				},
            ],
            // 市级权限
            columns1: [
				{
					title: "姓名",
					dataIndex: "agentName",
					width: "120px",
                    key: "agentName",
                    fixed: 'left',
				},
				{
					title: "手机号",
					dataIndex: "phone",
					width: "136px",
					key: "phone",
				},
				{
					title: "代理等级",
					dataIndex: "agentLevel",
					width: "120px",
					key: "agentLevel",
				},
				{
					title: "所属区域代理",
					dataIndex: "belongCounty",
					width: "120px",
					key: "belongCounty",
				},
				{
					title: "代理区域",
					dataIndex: "agentArea",
					width: "192px",
					key: "agentArea",
				},
				{
					title: "订单数量",
					dataIndex: "orderNum",
					width: "168px",
					key: "orderNum",
					align:'right',
					scopedSlots: { customRender: "orderNum" },
				},
				{
					title: "分成比例",
					dataIndex: "division",
					width: "120px",
					align:'right',
                    key: "division",
                    scopedSlots: { customRender: "division" },
				},
				{
					title: "累计收益",
					dataIndex: "totalIncome",
					width: "120px",
					align:'right',
					key: "totalIncome",
				},
				{
					title: "可提现收益",
					dataIndex: "canDrawCash",
					width: "120px",
					align:'right',
					key: "canDrawCash",
				},
				{
					title: "代理状态",
					dataIndex: "enableFlag",
					width: "120px",
					key: "enableFlag",
					scopedSlots: { customRender: "enableFlag" },
				},
				{
					title: "加入时间",
					dataIndex: "startTime",
					width: "184px",
					key: "startTime",
				},
				{
					title: "到期时间",
					dataIndex: "endTime",
					width: "184px",
					key: "endTime",
				},
				{
					title: "操作",
					dataIndex: "operation",
					width: "200px",
					key: "operation",
					scopedSlots: { customRender: "operation" },
					fixed: 'right',
				},
			],
            // 区级权限
            columns2: [
				{
					title: "姓名",
					dataIndex: "agentName",
					width: "120px",
                    key: "agentName",
                    fixed: 'left',
				},
				{
					title: "手机号",
					dataIndex: "phone",
					width: "136px",
					key: "phone",
				},
				{
					title: "代理等级",
					dataIndex: "agentLevel",
					width: "120px",
					key: "agentLevel",
				},
				{
					title: "订单数量",
					dataIndex: "orderNum",
					width: "168px",
					key: "orderNum",
					align:'right',
					scopedSlots: { customRender: "orderNum" },
				},
				{
					title: "分成比例",
					dataIndex: "division",
					width: "120px",
					align:'right',
                    key: "division",
                    scopedSlots: { customRender: "division" },
				},
				{
					title: "累计收益",
					dataIndex: "totalIncome",
					width: "120px",
					align:'right',
					key: "totalIncome",
				},
				{
					title: "可提现收益",
					dataIndex: "canDrawCash",
					width: "120px",
					align:'right',
					key: "canDrawCash",
				},
				{
					title: "代理状态",
					dataIndex: "enableFlag",
					width: "120px",
					key: "enableFlag",
					scopedSlots: { customRender: "enableFlag" },
				},
				{
					title: "加入时间",
					dataIndex: "startTime",
					width: "184px",
					key: "startTime",
				},
				{
					title: "到期时间",
					dataIndex: "endTime",
					width: "184px",
					key: "endTime",
				},
				{
					title: "操作",
					dataIndex: "operation",
					width: "200px",
					key: "operation",
					scopedSlots: { customRender: "operation" },
					fixed: 'right',
				},
			],
			tabledData: [],
			// 权限按钮
			isShowAdd: false,
			isShowEdit: false,
			isShowDisable: false,
            isShowEnable: false,

            // 代理等级数组
            gradeList: [],
            // 开始/结束时间
            starTim: '',
            endTime: '',

            // 获取小程序二维码数组
            appCodeList:[],
		};
    },
	methods: {
        // 图片下载
        down(item){
            this.downloadIamge(item.qrCode, item.code)
        },
        downloadIamge (imgsrc, name) { // 下载图片地址和图片名
            var image = new Image()
            // 解决跨域 Canvas 污染问题
            image.setAttribute('crossOrigin', 'anonymous')
            image.onload = function () {
                var canvas = document.createElement('canvas')
                canvas.width = image.width
                canvas.height = image.height
                var context = canvas.getContext('2d')
                context.drawImage(image, 0, 0, image.width, image.height)
                var url = canvas.toDataURL('image/png') // 得到图片的base64编码数据
                var a = document.createElement('a') // 生成一个a元素
                var event = new MouseEvent('click') // 创建一个单击事件
                a.download = name || 'photo' // 设置图片名称
                a.href = url // 将生成的URL设置为a.href属性
                a.dispatchEvent(event) // 触发a的单击事件
            }
            image.src = imgsrc
        },

        // 获取小程序二维码
        isAppCode(record){
            Makeqrcode({
                agentNo:record.agentNo,
            }).then(({ code, data }) => {
                if (code === 200) {
                    this.appCodeList = data
                    this.visibleShow = true
                    this.spinShow = false
                }
            });
        },

		// 分页切换页数
		showSizeChangeFn(current, pageSize) {
			this.pageNo = current;
			this.pageSize = pageSize;
			this.agentListFn();
		},
		// 搜索按钮 , 回车搜索
        searchClick () {
            this.pageNo = 1
			this.isDisableSearch = true;
			setTimeout(() => {
			    this.isDisableSearch = false;
			}, 3000);
			this.agentListFn();
		},
		// 添加菜单按钮
		addMenu() {
			this.$router.push(`/agenManage/upertubeManage/AgenEdit/0`);
		},
		editClick(data) {
			this.$router.push(`/agenManage/upertubeManage/AgenEdit/${data.agentNo}`);
		},
		// 请求菜单列表数据
        agentListFn () {
            this.tableLoading = true
            AgentList({
                page:this.pageNo,
                enableFlag:this.formState.status == undefined ? 0 : this.formState.status,
                levelNo:this.formState.grade == 0 ? '' : this.formState.grade,
                phone:this.formState.phone,
                agentName:this.formState.name,
                startTime:this.starTime,
                endTime:this.endTime,
            }).then(({ code, data }) => {
                if (code === 200) {
                    this.tableLoading = false
                    this.count = data.count
                    this.tabledData = data.list
                } else {
                    this.tableLoading = false
                }
            });
        },

        // 获取代理等级列表
        agentGrade () {
            AgentLevel().then(({ code, data }) => {
                if (code === 200) {
                    this.gradeList = data
                }
            });
        },

        // 有效期监听
        termChange (val) {
            if (val.length == 0) {
                this.starTime = ''
                this.endTime = ''
            } else {
                this.starTime = format(new Date(val[0]._d), "yyyy-MM-dd" +' '+'00:00:00');
                this.endTime = format(new Date(val[1]._d), "yyyy-MM-dd" +' '+'00:00:00');
            }
        },

		// 启用
		enableClick(data) {
			Editenableflag({
                agentNo:data.agentNo,
                enableFlag:1,
            }).then(({ code, data }) => {
                if (code === 200) {
                    this.tableLoading = false
                    this.agentListFn()
                }
            });
		},
		// 禁用
        disableClick (data) {
			Editenableflag({
                agentNo:data.agentNo,
                enableFlag:0,
            }).then(({ code, data }) => {
                if (code === 200) {
                    this.tableLoading = false
                    this.agentListFn()
                }
            });
        },

        // 点击订单数量详情
        orderDetails (record) {
            this.$router.push(`/agenManage/upertubeManage/agenOrder/${record.agentNo}`)
        }
	},
};
</script>

<style lang="less" scoped>
.box {
  min-height: 100%;
  background-color: #fff;
  .header{
    width: 100%;
    background-color: #fff;
	margin-bottom: 16px;
    .title{
      padding: 14px 16px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);;
    }
	.content{
		padding: 14px 16px;
	}
  }
  .tableStyle{
	background-color: #fff;
	padding: 16px;
	.page{
		display: flex;
		justify-content: space-between;
		margin-top: 20px;
		padding-right: 10px;
	}
  }
}
.contentCode{
    text-align: center;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    .codeItem{
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        .name{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex: 1;
            .img{
                width: 80px;
                height: 80px;
                margin-right: 14px;
            }
            .size{
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #383838;
            }
        }
        .sizexz{
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FF8200;
            display: flex;
            align-items: center;
        }
    }
}
</style>

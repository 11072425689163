// 成本管理相关接口
import request from "@/request/request";

// 用户列表
export const UserList = (params) => request.post(`/agent/admin/user/userlist`, params);

// 用户列表导出数据
export const ExporTuser = (params) => request.post(`/agent/admin/user/exportuser`, params);




